import { createApp, markRaw } from "vue";
import Cookies from "js-cookie";
import "element-plus/theme-chalk/index.css";
import "normalize.css/normalize.css";
import Element from "element-plus";
import "./styles/element-variables.scss";
import "@/styles/index.scss";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./icons"; // icon
import "./permission"; // permission control
import locale from "element-plus/dist/locale/zh-cn.min.mjs"; // 引入element中文包
import * as ElementPlusIconsVue from "@element-plus/icons-vue"; // 引入element图标
import * as filters from "./filters"; // global filters
import { debounce } from "lodash-es";
const app = createApp(App);

/** 引入element-plus */
app.use(Element, {
  size: Cookies.get("size") || "default",
  locale,
});

Object.keys(filters).forEach((key) => {
  (
    app.config.globalProperties.$filters ||
    (app.config.globalProperties.$filters = {})
  ).key = filters[key];
});

/* eslint no-extend-native: ["error", { "exceptions": ["Date"] }] */
Date.prototype.Format = function (fmt) {
  const o = {
    "M+": this.getMonth() + 1, // 月份
    "d+": this.getDate(), // 日
    "h+": this.getHours(), // 小时
    "m+": this.getMinutes(), // 分
    "s+": this.getSeconds(), // 秒
    "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
    S: this.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
};

/** 注册svg-icon */
import SvgIcon from "@/components/SvgIcon"; // svg component
app.component("svg-icon", SvgIcon);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, markRaw(component));
}

/** 注册权限指令 */
const remove = (el) => el.parentNode.removeChild(el);
app.directive("auth", {
  mounted: (el, binding) => {
    let authList = [];
    // 获取当前用户的权限列表
    const { authObj } = store.state.user;
    if (authObj) {
      if (
        authObj &&
        authObj.authority_list &&
        authObj.authority_list.length > 0
      ) {
        authList = authObj.authority_list;
      }
    }
    // 获取传入的权限码value修饰符modifiers(.some,.every => 后期需要补足)
    const { value } = binding;
    if (authList.indexOf(value) === -1) {
      remove(el);
    }
  },
});

app.use(router);
app.config.globalProperties.routerAppend = (path, pathToAppend) => {
  return path + (path.endsWith("/") ? "" : "/") + pathToAppend;
};
app.use(store);
app.mount("#app");

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(cb) {
    cb = debounce(cb, 200);
    super(cb);
  }
};
