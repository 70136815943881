import Layout from '@/layout';

const xiaoeRouter = [
  {
    path: '/xiaoetong',
    component: Layout,
    redirect: '/xiaoetong/member_list',
    name: 'xiaoetong',
    meta: {
      title: '小鹅通模块',
      icon: 'xet',
      auth: 'xet'
    },
    children: [
      {
        path: 'member_list',
        component:
          () => import('@/modules/xiaoetong/views/wx_member_list/index'),
        name: 'xet_member_list',
        meta: { title: '会员列表', noCache: true, auth: 'xet_user_bind_list' }
      },
      {
        path: 'act_list',
        component:
          () => import('@/modules/xiaoetong/views/activity_list/index'),
        name: 'xet_act_list',
        meta: { title: '活动列表', noCache: true, auth: 'xet_activity_list' }
      },
      {
        path: 'app_list',
        component:
          () => import('@/modules/xiaoetong/views/app_list/index'),
        name: 'xet_app_lsit',
        meta: { title: '应用列表', noCache: true, auth: 'xet_app_list' }
      },
      {
        path: 'bind_config',
        component:
          () => import('@/modules/xiaoetong/views/bind_config/index'),
        name: 'bind_config',
        meta: { title: '绑定设置', noCache: true, auth: 'xet_bind' }
      }
    ]
  }
];

export default xiaoeRouter;
