import Layout from '@/layout';

const dashBoardRouter = [
  {
    path: '/dashboard',
    component: Layout,
    redirect: '/dashboard/index',
    name: 'dashboard',
    meta: {
      title: '仪表盘',
      icon: 'dashboard',
      auth: 'dashboard'
    },
    children: [
      {
        path: 'index',
        component: () => import('@/modules/admin/views/dashboard/index'),
        name: 'dashboard-index',
        meta: {
          title: '仪表盘',
          auth: 'dashboard',
          icon: 'dashboard',
          affix: true
        }
      }
    ]
  }
];

export default dashBoardRouter;
