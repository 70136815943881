import Layout from '@/layout';

const constantRouter = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component:
          () => import('@/modules/admin/views/redirect/index')
      }
    ]
  },
  {
    path: '/login',
    component:
      () => import('@/modules/user/views/login/index'),
    hidden: true
  },
  {
    path: '/register',
    component:
      () => import('@/modules/user/views/register/index'),
    hidden: true
  },
  {
    path: '/404',
    component:
      () => import('@/modules/admin/views/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component:
      () => import('@/modules/admin/views/error-page/401'),
    hidden: true
  }
];

export default constantRouter;
