import Layout from '@/layout';

const sysRouter = [
  {
    path: '/my',
    component: Layout,
    redirect: '/my/personal-setting',
    name: 'my',
    meta: {
      title: '我的',
      icon: 'user-solid',
      noNeedAuth: true
    },
    children: [
      {
        path: 'personal-setting',
        component:
          () => import('@/modules/system/views/personal-setting/index'),
        name: 'system-personal-setting',
        meta: { title: '个人设置', noCache: true, noNeedAuth: true }
      },
      {
        path: 'update-password',
        component:
          () => import('@/modules/system/views/updatePassword/index'),
        name: 'system-update-password',
        meta: { title: '修改密码', noCache: true, noNeedAuth: true }
      }
    ]
  }
];

export default sysRouter;
