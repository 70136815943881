import Layout from '@/layout';

const wechatRouter = [
  {
    path: '/admin_wx',
    component: Layout,
    redirect: '/admin_wx/list',
    name: 'admin_wx',
    meta: {
      title: '微信模块',
      icon: 'wechat',
      auth: 'wechat'
    },
    children: [
      {
        path: 'list',
        component:
          () => import('@/modules/wechat/views/wx_bind_list/index'),
        name: 'admin_wx-list',
        meta: {
          title: '微信绑定列表',
          noCache: true,
          auth: 'wechat_bind_list'
        }
      },
      {
        path: 'history',
        component:
          () => import('@/modules/wechat/views/wx_history_list/index'),
        name: 'admin_wx-history',
        meta: { title: '历史日志', noCache: true, auth: 'wechat_history' }
      }
    ]
  }
];

export default wechatRouter;
