import Layout from '@/layout';

const sysRouter = [
  {
    path: '/system',
    component: Layout,
    redirect: '/system/auth',
    name: 'system',
    meta: {
      title: '管理员',
      icon: 'setting',
      auth: 'system'
    },
    children: [
      {
        path: 'user',
        component:
          () => import('@/modules/system/views/user/index'),
        name: 'system-user',
        meta: {
          title: '管理员列表',
          noCache: true,
          auth: 'system_admin_user_list'
        }
      },
      {
        path: 'auth',
        component:
          () => import('@/modules/system/views/auth/index'),
        name: 'system-auth',
        meta: {
          title: '权限列表',
          noCache: true,
          auth: 'system_admin_authority_list'
        }
      },
      {
        path: 'roles',
        component:
          () => import('@/modules/system/views/roles/index'),
        name: 'system-roles',
        meta: {
          title: '角色列表',
          noCache: true,
          auth: 'system_admin_role_list'
        }
      },
      {
        path: 'org',
        component:
          () => import('@/modules/system/views/org/index'),
        name: 'system-org',
        meta: {
          title: '组织列表',
          noCache: true,
          auth: 'system_organization_list'
        }
      },
      {
        path: 'org-setting',
        component:
          () => import('@/modules/system/views/org-setting/index'),
        name: 'system-org-setting',
        meta: {
          title: '组织设置',
          noCache: true,
          auth: 'system_organization_setting'
        }
      },
      {
        path: 'daliy',
        component:
          () => import('@/modules/system/views/daliy/index'),
        name: 'system-daliy',
        meta: { title: '系统日志', noCache: true, auth: 'system_daliy' }
      }
    ]
  }
];

export default sysRouter;
