import Cookies from 'js-cookie';

class Storage {
  set(key, value, expires = null) {
    if (expires) {
      return Cookies.set(key, value, { expires });
    } else {
      return Cookies.set(key, value);
    }
  }
  get(key) {
    return Cookies.get(key);
  }
  clear(key) {
    return Cookies.remove(key);
  }
}
const storage = new Storage();

export default storage;
